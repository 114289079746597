
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AppComponent } from './app.component';
import { CoreModule } from '@core/core.module';
import { AppThemeModule } from '@app/modules/theme/app-theme.module';
import { AppRoutingModule } from '@app/app-routing.module';
import { MatNativeDateModule } from '@angular/material/core';
import LogRocket from 'logrocket';
/**
 * mixer project
 * .com
 */
LogRocket.init('vsai95/mixer-jlvau');


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    CoreModule,
    AppThemeModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
  ],
  providers: [
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
