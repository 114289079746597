/**
 * This is the theme module of the application, It contain the application theme related dependencies
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialComponentsModule } from '@theme/material-components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';





const components = [
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    MaterialComponentsModule,
    ReactiveFormsModule,

  ],
  exports: [
    MaterialComponentsModule,
    ...components,
    ReactiveFormsModule
  ]
})
export class AppThemeModule { }
