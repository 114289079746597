/**
 * Base routing module of the application.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';


// Define the routes

const routes: Routes = [
  {
    path: 'session',
    loadChildren: () => import('./modules/session/session.module').then(m => m.SessionModule)
  },
  {
    path: '', pathMatch: 'full', redirectTo: '/session/room'
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
